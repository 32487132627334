import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateRequest } from '@/actions/wizard/RequestWizardActions';
import Input from '@/elements/Input';

const Label = ({ title, description }) => (
    <>
        <strong>{title}</strong>
        {description && <p className="fs-14 fw-normal m-0">{description}</p>}
    </>
);

export default function BudgetCheckBoxes({ validateBudget }) {
    const dispatch = useDispatch();
    const { request, meta } = useSelector((state) => state.requestWizard);

    const GroceryCheckBox = () => {
        const chefHireCheck = request.type === 'Multi-day chef hire' && meta.variant === 'feature_wizard_budget_options';
        const bbqCheck = request.servingType === 'BBQ' && meta.variant === 'control' && request.guests > 30;

        useEffect(() => {
            if (chefHireCheck || bbqCheck) return;
            if (request.budgetType == 10) { // BBQ Chef Only
                dispatch(updateRequest('includingGrocery', false));
            } else {
                dispatch(updateRequest('includingGrocery', true));
            }
        }, [request.servingType]);
        
        if (!chefHireCheck && !bbqCheck) return;

        return (
            <Input
                inputType="checkbox"
                label={
                    <Label
                        title="I want the chef to bring groceries"
                        description="Untick this box if you want to provide the groceries yourself"
                    />
                }
                isSelected={request.includingGrocery}
                handleChange={(v) => {
                    dispatch(updateRequest('includingGrocery', v));
                    validateBudget(request.budget, request.budget_pp, v);
                }}
                className="input__checkbox -tick -unboxed"
            />
        );
    };

    const BBQCheckBox = () => {
        if (request.servingType !== 'BBQ') return;

        return (
            <Input
                inputType="checkbox"
                label={
                    <Label
                        title="I have a BBQ"
                        description="Untick this box if you need the chef to provide a BBQ"
                    />
                }
                isSelected={!request.includingBbq}
                handleChange={(v) => {
                    dispatch(updateRequest('includingBbq', !v));
                    validateBudget();
                }}
                className="input__checkbox -tick -unboxed"
            />
        );
    };

    return (
        <>
            <GroceryCheckBox />
            <BBQCheckBox />
        </>
    );
}
