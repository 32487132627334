import WizardFeatures from '@/utils/Features/Wizard/WizardFeatures';
import { steps } from './RequestWizardSteps';
import { prompts } from './RequestWizardPrompts';
import {
    Cake,
    Heart,
    Martini,
    CallBell,
    Confetti,
    ForkKnife,
    HouseLine,
    UsersThree,
    Baby,
    Balloon,
    Briefcase,
    Calendar,
    UserCircleGear,
} from '@phosphor-icons/react';
import { aws } from '@/config';

export const status = {
    init: false,
    loading: true,
    submitting: false,
    submitted: false,
    pending: false,
};

export const meta = {
    // Timestamps
    startedAt: false,
    exitedAt: false,

    // Tracking data
    exitSource: false,
    exitCopy: false,
    source: false,
    ctaCopy: false,

    // Allocation
    embed: false,
    modal: true,
    modalId: false,
    autoShow: false,
    autoShowDelay: false,

    // Data
    step: 1,
    stepAction: false,
    stepName: 'event-type',
    stepPrev: false,
    activePrompt: 'none',
    isMobile: false,
    variant: WizardFeatures.budgetOptions()
        ? 'feature_wizard_budget_options'
        : 'control',
    quoteId: false,
    version: 2,
};

export const quoteRequest = {
    guests: 8,
    mealDate: [],
    postcode: '',
    dietaryPreferences: [],
    type: '',
    cuisines: [],
    budget: '',
    budget_pp: '',
    budgetEntry: 'total',
    budgetType: 0,
    foodPreferences: '',
    additional_info_length: 0,
    servingType: '',
    includingGrocery: true,
    includingBbq: false,
};

export const options = {
    // API data
    chefsCount: 0,
    isInternational: false,
    isValidLocation: false,
    cities: [],
    postcodes: [],
    cuisines: [],
    dietaries: [],
    existingRequests: [],

    // Config data
    steps: steps,
    prompts: prompts,
    suggestedBudget: false,
    type: [
        {
            label: 'Birthday',
            icon: Cake,
        },
        {
            label: 'Get together',
            icon: ForkKnife,
        },
        {
            label: 'Hen / Stag do',
            icon: Martini,
        },
        {
            label: 'Anniversary',
            icon: Heart,
        },
        // {
        //     label: 'Kids party',
        //     icon: Balloon,
        // },
        {
            label: 'Family event',
            icon: UsersThree,
        },
        {
            label: 'Engagement',
            icon: Confetti,
        },

        {
            label: 'Baby shower',
            icon: Baby,
        },
        {
            label: 'Meal prep',
            icon: HouseLine,
        },
        {
            label: 'Work event',
            icon: Briefcase,
        },
        {
            label: 'Multi-day chef hire',
            icon: Calendar,
        },
        {
            label: 'Full-time chef',
            icon: UserCircleGear,
        },
        {
            label: 'Other',
            icon: CallBell,
        },
    ],
    servingType: [
        {
            label: '3 course meal',
            // price: '£££',
            image: aws.url + '/images/wizard/food/3-course-meal.webp',
        },
        {
            label: '4-5 course meal',
            // price: '££££',
            image: aws.url + '/images/wizard/food/4-5-course-meal.webp',
        },
        {
            label: '6-9 course meal',
            // price: '££',
            image: aws.url + '/images/wizard/food/fine-dining.webp',
        },
        {
            label: 'BBQ',
            // price: '££',
            image: aws.url + '/images/wizard/food/bbq.webp',
        },
        {
            label: 'Sharing/buffet style',
            // price: '££',
            image: aws.url + '/images/wizard/food/buffet.webp',
        },
        {
            label: 'Canapes & drinks',
            // price: '£-££',
            image: aws.url + '/images/wizard/food/canapes.webp',
        },
        {
            label: 'Brunch',
            // price: '£',
            image: aws.url + '/images/wizard/food/brunch.webp',
        },
        {
            label: 'Afternoon tea',
            // price: '£££',
            image: aws.url + '/images/wizard/food/afternoon-tea.webp',
        },
        {
            label: 'Cooking class',
            // price: '£££',
            image: aws.url + '/images/wizard/food/cooking-class.webp',
        },
        {
            label: 'Kids party',
            // price: '££',
            image: aws.url + '/images/wizard/food/kids-party.webp',
        },
        {
            label: 'Delivery platters',
            // price: '££',
            image: aws.url + '/images/wizard/food/delivery-platters.webp',
        },
        {
            label: 'Grazing table',
            // price: '££',
            image: aws.url + '/images/wizard/food/grazing-table.webp',
        },
    ],
    mealPrepType: [
        {
            label: 'Healthy & nutritious',
            image:
                aws.url +
                '/images/wizard/food/meal-prep/healthy-nutritious.webp',
        },
        {
            label: 'Clean meal prep',
            image:
                aws.url + '/images/wizard/food/meal-prep/clean-meal-prep.webp',
        },
        {
            label: 'Keto diet',
            image: aws.url + '/images/wizard/food/meal-prep/keto-diet.webp',
        },
        {
            label: 'Indulgent cooking',
            image:
                aws.url +
                '/images/wizard/food/meal-prep/indulgent-cooking.webp',
        },
        {
            label: 'Ethnic cooking',
            image:
                aws.url + '/images/wizard/food/meal-prep/ethnic-cooking.webp',
        },
        {
            label: 'Clean & indulgent mix',
            image:
                aws.url +
                '/images/wizard/food/meal-prep/clean-indulgent-mix.webp',
        },
    ],
    budgetTypes: [
        {
            conditions: [
                {
                    key: 'type',
                    value: 'Meal prep',
                    operator: '===',
                    dataSet: 'request',
                },
            ],
            description: (
                <>
                    £250-£400 for 15-20 meals including the cost of groceries.
                    The exact cost depends on the chef's rate and the type of
                    meals and ingredients.
                    <br />
                    E.g. Pescatarian will cost more than vegetarian meal prep.
                    You'll be able to share more information about your
                    requirements in the next step.
                </>
            ),
        },
        // {
        //     variant: 'feature_wizard_budget_options',
        //     type: 'Multi-day chef hire',
        //     options: [
        //         {
        //             value: 4,
        //             label: 'Standard chef',
        //             minSpend: 200,
        //             description: 'E.g. An experienced private chef',
        //         },
        //         {
        //             value: 5,
        //             label: 'Premium chef',
        //             minSpend: 600,
        //             description:
        //                 'E.g. A premium private chef with fine dining experience',
        //         },
        //         {
        //             value: 6,
        //             label: 'Luxury chef',
        //             minSpend: 2000,
        //             description:
        //                 'E.g. A renowned high-end chef with top credentials',
        //         },
        //     ],
        // },
        {
            conditions: [
                {
                    key: 'type',
                    value: 'Multi-day chef hire',
                    operator: '===',
                    dataSet: 'request',
                },
            ],
            description:
                'For a group of 10 people, preparing all meals could cost £600-1000 per day including groceries. Or if you would like to take care of the groceries, you can expect a £200-£500 day rate depending on type of chef.',
        },
        // {
        //     variant: 'feature_wizard_budget_options',
        //     type: 'Full-time chef',
        //     options: [
        //         {
        //             value: 4,
        //             label: 'Standard chef',
        //             minSpend: 200,
        //             description: 'E.g. An experienced private chef',
        //         },
        //         {
        //             value: 5,
        //             label: 'Premium chef',
        //             minSpend: 600,
        //             description:
        //                 'E.g. A premium private chef with fine dining experience',
        //         },
        //         {
        //             value: 6,
        //             label: 'Luxury chef',
        //             minSpend: 2000,
        //             description:
        //                 'E.g. A renowned high-end chef with top credentials',
        //         },
        //     ],
        // },
        {
            conditions: [
                {
                    key: 'type',
                    value: 'Full-time chef',
                    operator: '===',
                    dataSet: 'request',
                },
            ],
            description:
                'Our chefs have varying rates. For full-time private chef positions we recommend entering a day rate times the number of days per month you need them to work. Depending on the calibre of chef, their rates start at £200/day and can range to £3000/day.',
        },
        // {
        //     servingType: '3 course meal',
        //     variant: 'feature_wizard_budget_options',
        //     options: [
        //         {
        //             value: 4,
        //             label: 'Standard chef',
        //             priceRange: [40, 80],
        //             minSpend: 300,
        //             description:
        //                 'E.g. An experienced private chef using high quality produce',
        //         },
        //         {
        //             value: 5,
        //             label: 'Premium chef',
        //             priceRange: [80, 200],
        //             minSpend: 1000,
        //             description:
        //                 'E.g. A premium private chef using high quality produce',
        //         },
        //         {
        //             value: 6,
        //             label: 'Luxury chef',
        //             priceRange: [200],
        //             minSpend: 3000,
        //             description:
        //                 'E.g. A renowned high-end chef using expensive, top quality produce',
        //         },
        //     ],
        // },
        // {
        //     servingType: '4-5 course meal',
        //     variant: 'feature_wizard_budget_options',
        //     options: [
        //         {
        //             value: 4,
        //             label: 'Standard chef',
        //             priceRange: [70, 100],
        //             minSpend: 400,
        //             description:
        //                 'E.g. An experienced private chef using high quality produce',
        //         },
        //         {
        //             value: 5,
        //             label: 'Premium chef',
        //             priceRange: [100, 250],
        //             minSpend: 1000,
        //             description:
        //                 'E.g. A premium private chef using high quality produce',
        //         },
        //         {
        //             value: 6,
        //             label: 'Luxury chef',
        //             priceRange: [250],
        //             minSpend: 3000,
        //             description:
        //                 'E.g. A renowned high-end chef using expensive, top quality produce',
        //         },
        //     ],
        // },
        // {
        //     servingType: '6-9 course meal',
        //     variant: 'feature_wizard_budget_options',
        //     options: [
        //         {
        //             value: 4,
        //             label: 'Standard chef',
        //             priceRange: [90, 150],
        //             minSpend: 600,
        //             description:
        //                 'E.g. An experienced private chef using high quality produce',
        //         },
        //         {
        //             value: 5,
        //             label: 'Premium chef',
        //             priceRange: [150, 300],
        //             minSpend: 1000,
        //             description:
        //                 'E.g. A premium private chef using high quality produce',
        //         },
        //         {
        //             value: 6,
        //             label: 'Luxury chef',
        //             priceRange: [300],
        //             minSpend: 3000,
        //             description:
        //                 'E.g. A renowned high-end chef using expensive, top quality produce',
        //         },
        //     ],
        // },
        {
            conditions: [
                {
                    key: 'servingType',
                    value: 'BBQ',
                    operator: '===',
                    dataSet: 'request',
                },
                {
                    key: 'variant',
                    value: 'control',
                    operator: '===',
                    dataSet: 'meta',
                },
                {
                    key: 'guests',
                    value: 30,
                    operator: '<=',
                    dataSet: 'request',
                },
            ],
            description: (
                <>
                    <b>With groceries:</b> £500-£1600 depending on the chef's
                    rate and the amount of dishes and quality of food. You'll be
                    able to share more information in the next step.
                </>
            ),
        },
        {
            conditions: [
                {
                    key: 'servingType',
                    value: 'BBQ',
                    operator: '===',
                    dataSet: 'request',
                },
                {
                    key: 'variant',
                    value: 'control',
                    operator: '===',
                    dataSet: 'meta',
                },
                {
                    key: 'guests',
                    value: 30,
                    operator: '>',
                    dataSet: 'request',
                },
            ],
            description: (
                <>
                    <b>Without groceries:</b> £300-£800 depending on the chef's
                    rate and the number of hours.
                    <br />
                    <b>With groceries:</b> £500-£2000 depending on the chef's
                    rate and the amount of food. You'll be able to share more
                    information in the next step.
                </>
            ),
        },
        {
            conditions: [
                {
                    key: 'servingType',
                    value: 'BBQ',
                    operator: '===',
                    dataSet: 'request',
                },
                {
                    key: 'variant',
                    value: 'feature_wizard_budget_options',
                    operator: '===',
                    dataSet: 'meta',
                },
                {
                    key: 'guests',
                    value: 30,
                    operator: '<=',
                    dataSet: 'request',
                },
            ],
            options: [
                {
                    label: 'Standard BBQ',
                    priceRange: [40, 70],
                    minSpend: 600,
                    value: 7,
                },
                {
                    label: 'Premium BBQ',
                    priceRange: [70, 150],
                    minSpend: 1000,
                    value: 8,
                },
                {
                    label: 'Luxury BBQ',
                    priceRange: [150],
                    minSpend: 3000,
                    value: 9,
                },
            ],
        },
        {
            conditions: [
                {
                    key: 'servingType',
                    value: 'BBQ',
                    operator: '===',
                    dataSet: 'request',
                },
                {
                    key: 'variant',
                    value: 'feature_wizard_budget_options',
                    operator: '===',
                    dataSet: 'meta',
                },
                {
                    key: 'guests',
                    value: 30,
                    operator: '>',
                    dataSet: 'request',
                },
            ],
            options: [
                {
                    label: 'BBQ Chef Only',
                    minSpend: 300,
                    description: `You will provide the groceries`,
                    value: 10,
                },
                {
                    label: 'Premium BBQ',
                    priceRange: [70, 150],
                    minSpend: 1000,
                    value: 8,
                },
                {
                    label: 'Luxury BBQ',
                    priceRange: [150],
                    minSpend: 3000,
                    value: 9,
                },
            ],
        },
        // {
        //     servingType: 'Sharing/buffet style',
        //     variant: 'feature_wizard_budget_options',
        //     options: [
        //         {
        //             value: 4,
        //             label: 'Standard chef',
        //             priceRange: [40, 80],
        //             minSpend: 300,
        //             description:
        //                 'E.g. An experienced private chef, 3-4 menu items using basic produce',
        //         },
        //         {
        //             value: 5,
        //             label: 'Premium chef',
        //             priceRange: [70, 200],
        //             minSpend: 1000,
        //             description:
        //                 'E.g. A premium private chef, 5-7 menu items using high quality produce',
        //         },
        //         {
        //             value: 6,
        //             label: 'Luxury chef',
        //             priceRange: [200],
        //             minSpend: 3000,
        //             description:
        //                 'E.g. A renowned high-end chef, 8-10 menu items using highest quality produce',
        //         },
        //     ],
        // },
        // {
        //     servingType: 'Canapes & drinks',
        //     variant: 'feature_wizard_budget_options',
        //     options: [
        //         {
        //             value: 4,
        //             label: 'Standard chef',
        //             priceRange: [30, 70],
        //             minSpend: 300,
        //             description:
        //                 'E.g. An experienced private chef, 6-9 canapés pp using basic produce',
        //         },
        //         {
        //             value: 5,
        //             label: 'Premium chef',
        //             priceRange: [70, 100],
        //             minSpend: 1000,
        //             description:
        //                 'E.g. A premium private chef, 7-10 canapés pp using high quality produce',
        //         },
        //         {
        //             value: 6,
        //             label: 'Luxury chef',
        //             priceRange: [100],
        //             minSpend: 3000,
        //             description:
        //                 'E.g. A renowned high-end chef, 10+ canapés pp using highest quality produce',
        //         },
        //     ],
        // },
        // {
        //     servingType: 'Brunch',
        //     variant: 'feature_wizard_budget_options',
        //     options: [
        //         {
        //             value: 4,
        //             label: 'Standard chef',
        //             priceRange: [30, 60],
        //             minSpend: 300,
        //             description:
        //                 'E.g. An experienced private chef, 4-5 sweet/savoury brunch options',
        //         },
        //         {
        //             value: 5,
        //             label: 'Premium chef',
        //             priceRange: [60, 100],
        //             minSpend: 1000,
        //             description:
        //                 'E.g. A premium private chef, 5-7 sweet/savoury options, juices & smoothies',
        //         },
        //         {
        //             value: 6,
        //             label: 'Luxury chef',
        //             priceRange: [100],
        //             minSpend: 3000,
        //             description:
        //                 'E.g. A renowned high-end chef, 7-9 sweet/savoury options, juices & smoothies',
        //         },
        //     ],
        // },
        // {
        //     servingType: 'Afternoon tea',
        //     variant: 'feature_wizard_budget_options',
        //     options: [
        //         {
        //             value: 4,
        //             label: 'Standard chef',
        //             priceRange: [40, 80],
        //             minSpend: 300,
        //             description:
        //                 'E.g. An experienced private chef using high quality produce',
        //         },
        //         {
        //             value: 5,
        //             label: 'Premium chef',
        //             priceRange: [80, 200],
        //             minSpend: 1000,
        //             description:
        //                 'E.g. A premium private chef using high quality produce',
        //         },
        //         {
        //             value: 6,
        //             label: 'Luxury chef',
        //             priceRange: [200],
        //             minSpend: 3000,
        //             description:
        //                 'E.g. A renowned high-end chef using expensive, top quality produce',
        //         },
        //     ],
        // },
        // {
        //     servingType: 'Cooking class',
        //     variant: 'feature_wizard_budget_options',
        //     options: [
        //         {
        //             value: 4,
        //             label: 'Standard chef',
        //             priceRange: [60, 100],
        //             minSpend: 300,
        //             description:
        //                 'E.g. An experienced private chef using good produce',
        //         },
        //         {
        //             value: 5,
        //             label: 'Premium chef',
        //             priceRange: [100, 200],
        //             minSpend: 1000,
        //             description:
        //                 'E.g. A premium private chef using high quality produce',
        //         },
        //         {
        //             value: 6,
        //             label: 'Luxury chef',
        //             priceRange: [200],
        //             minSpend: 3000,
        //             description:
        //                 'E.g. A renowned high-end chef using expensive, top quality produce',
        //         },
        //     ],
        // },
        {
            conditions: [
                {
                    key: 'servingType',
                    value: 'Cooking class',
                    operator: '===',
                    dataSet: 'request',
                },
            ],
            description: `Our chefs set their own minimum spend which ranges from £300 to £1000+ for more experienced chefs, like Michelin-trained or MasterChefs. Please enter a rough budget so we can match you to relevant chefs.`,
        },
        {
            conditions: [
                {
                    key: 'servingType',
                    value: 'Kids party',
                    operator: '===',
                    dataSet: 'request',
                },
            ],
            description: `Our chefs set their own minimum spend which starts from £250. Please enter a rough total budget so we can match you to relevant chefs. You can share more information about the type of kids (and adult) food you require in the next step.`,
        },
        {
            conditions: [
                {
                    key: 'servingType',
                    value: 'Delivery platters',
                    operator: '===',
                    dataSet: 'request',
                },
            ],
            description: `The pricing can be anywhere between £240-480 range. The top of the range gets you more food, more variety of dishes, and access to premium chefs.`,
        },
        {
            conditions: [
                {
                    key: 'servingType',
                    value: 'Grazing table',
                    operator: '===',
                    dataSet: 'request',
                },
            ],
            description: `The pricing can be anywhere starting from £400-£2000 depending on the size of the table and the types of food. The top of the range means more premium food, e.g. sushi, sliders etc. as part of the grazing table, as well as more variety beyond just charcuterie, nibbles, dips etc.`,
        },
        {
            // Default options when no personalisation is available
            default: true,
            options: [
                {
                    label: 'Casual dining',
                    priceRange: [40, 70],
                    minSpend: 200,
                    value: 1,
                },
                {
                    label: 'Fine dining',
                    priceRange: [70, 200],
                    minSpend: 1000,
                    value: 2,
                },
                {
                    label: 'Celebrity chefs',
                    priceRange: [200],
                    minSpend: 3000,
                    value: 3,
                },
            ],
        },
    ],
};

export const initialState = {
    meta: meta,
    status: status,
    request: quoteRequest,
    options: options,
};
