export function textAreaAdjust(el, minHeight = false) {
    let target = el?.target ? el?.target : el;
    target.style.height = '1px';

    if (minHeight) {
        target.style.height = Math.max(target.scrollHeight, minHeight) + 'px';
    } else {
        target.style.height = target.scrollHeight + 'px';
    }
}

export function getLocalStorage(key, defaultValue) {
    if (is_node) return defaultValue;

    const saved = localStorage.getItem(key);
    const initial = JSON.parse(saved);
    return initial || defaultValue;
}

export function getSessionStorage(key, defaultValue) {
    const initial = sessionStorage.getItem(key);
    return initial || defaultValue;
}

export function checkIsSelected(key, values) {
    return values == key || (Array.isArray(values) && values.includes(key));
}

export function checkIsSelectedGroup(key, values) {
    return values.find((element) => element.value == key);
}

// create function that removes the .modal-backdrop div
export function removeBackdrop() {
    const backdrop = document.querySelector('.modal-backdrop');
    backdrop.parentNode.removeChild(backdrop);
}

/**
 * Calculate the Levenshtein distance between two strings.
 * The Levenshtein distance is a measure of the similarity between two strings,
 * defined as the minimum number of single-character edits (insertions, deletions, or substitutions)
 * required to change one string into the other.
 * https://en.wikipedia.org/wiki/Levenshtein_distance
 *
 * @param {string} a - The first string.
 * @param {string} b - The second string.
 * @returns {number} - The Levenshtein distance between the two strings.
 */
export function getLevenshteinDistance(a, b) {
    // Initialize a matrix with dimensions (a.length + 1) x (b.length + 1)
    const matrix = Array.from({ length: a.length + 1 }, (_, i) =>
        Array.from({ length: b.length + 1 }, (_, j) => (i === 0 ? j : i))
    );

    // Populate the matrix using the Levenshtein distance algorithm.
    for (let i = 1; i <= a.length; i++) {
        for (let j = 1; j <= b.length; j++) {
            matrix[i][j] =
                a[i - 1] === b[j - 1]
                    ? matrix[i - 1][j - 1] // No change needed if characters are the same.
                    : Math.min(
                          matrix[i - 1][j] + 1, // Deletion.
                          matrix[i][j - 1] + 1, // Insertion
                          matrix[i - 1][j - 1] + 1 // Substitution.
                      );
        }
    }

    // The Levenshtein distance is found at the bottom-right corner of the matrix
    return matrix[a.length][b.length];
}

/**
 * Calculate the similarity percentage between two strings based on the Levenshtein distance.
 *
 * @param {string} a - The first string.
 * @param {string} b - The second string.
 * @returns {number} - The similarity percentage between the two strings.
 */
export function getSimilarity(a, b) {
    const distance = getLevenshteinDistance(a, b);
    const maxLength = Math.max(a.length, b.length);
    return (1 - distance / maxLength) * 100;
}

/**
 * Remove similar items from an array based on a similarity threshold.
 * If two items have a similarity percentage greater than the threshold, only one is kept.
 *
 * @param {string[]} arr - The array of strings to process.
 * @param {number} similarityThreshold - The similarity threshold percentage.
 * @returns {string[]} - The array with similar items removed.
 */
export function removeSimilarities(arr, similarityThreshold) {
    const uniqueItems = [];

    arr.forEach((currentItem) => {
        let isSimilar = false;

        uniqueItems.forEach((existingItem, index) => {
            const similarity = getSimilarity(currentItem, existingItem);
            if (similarity > similarityThreshold) {
                isSimilar = true;
                // If currentItem is longer, replace the existing item
                if (currentItem.length > existingItem.length) {
                    uniqueItems[index] = currentItem;
                }
            }
        });

        if (!isSimilar) {
            uniqueItems.push(currentItem);
        }
    });

    return uniqueItems;
}

/**
 * Evaluate a condition based on an operator and two values.
 * 
 * @param {'===' | '!==' | '<' | '<=' | '>' | '>='} operator
 * @param {any} left 
 * @param {any} right 
 * @returns {boolean}
 */
export function evaluateCondition(operator, left, right) {
    switch (operator) {
        case '===':
            return left === right;
        case '!==':
            return left !== right;
        case '<':
            return left < right;
        case '<=':
            return left <= right;
        case '>':
            return left > right;
        case '>=':
            return left >= right;
        default:
            return left === right;
    }
}
