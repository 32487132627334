export const ukPhoneNumberRegexEdge = new RegExp(
    '^(((\\+?44\\s?\\d{4}|\\(?0\\d{4}\\)?)\\s?\\d{3}\\s?\\d{3})|((\\+?44\\s?\\d{3}|\\(?0\\d{3}\\)?)\\s?\\d{3}\\s?\\d{4})|((\\+?44\\s?\\d{2}|\\(?0\\d{2}\\)?)\\s?\\d{4}\\s?\\d{4}))(\\s?\\#(\\d{4}|\\d{3}))?$'
);

export const ukPhoneNumberRegex = /(\+?\d{2}\s?)?(\d{4}|\(\d{4}\))(\s?\d{3}\s?\d{3}|\s?\d{2}\s?\d{4}\s?\d{4})|\(\d{10,11}\)|\+(\w+)/g;

// Phone numbers
export const phoneRegex = /(\b\d{1,3}[-\s]?){9,}\d\b|(\+?\d{2,4}\s?)?(\(\d{2,4}\)|\d{2,4})(\s?\d{2,4}){2,3}\b|(\b\d(-\d){9,}\d\b)|(\b0\d{4}\s\d{3}\s\d{3}\b)/g;

// String of numbers (5+ digits)
export const longNumberRegex = /\b\d{5,}\b/g;

// String of words containing numbers
export const numberWordRegex = /\b(zero|one|two|three|four|five|six|seven|eight|nine|ten)(?:\s+(?:zero|one|two|three|four|five|six|seven|eight|nine|ten))+\b/gi;

// Links and Instagram tags
export const linkAndInstagramRegex = /(https?:\/\/(?!yhangry\.com)[^\s]+|www\.(?!yhangry\.com)[^\s]+|@\w+)/gi;

// Banned phrases and characters
export const wordNumberRegex = /\b(zero|one|two|three|four|five|six|seven|eight|nine)\b\D{0,15}(?!\d{1,2}[\/]\d{1,2}(?:[\/]\d{2,4})?|\d+\s*(?:course|courses))\d+/gi; // Matches words followed by numbers, but not dates or courses (max 15 characters between word and number to avoid matching normal sentences but still match "one then 2" or "one followed by 2")
export const mixedNumberWordRegex = /(?:zero|one|two|three|four|five|six|seven|eight|nine)\d+/gi;
export const bannedPhrasesRegex = /%|commission|agency|\d\.\)\s*\d{4,5}\s*\d\.\)\s*\d{3,4}\s*\d\.\)\s*\d{3,4}/gi;

// Email regex
export const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

// Website regex
export const websiteRegex = /(\b(?!www\.yhangry\.com)www\.\w+\.\w+|\b(?!yhangry\.com|e\.g|i\.e)\w+\.[^\d\s]+\b)/gi; // Matches all websites except yhangry.com, e.g. and i.e.

export const consolidatedPhoneNumberRegex = new RegExp(
    '(\\+44\\s?\\d{2,4}\\s?\\d{2,5}(\\s?\\d{2,5})?)' + // UK phone numbers with +44 (partial and complete)
    '|(\\(?0\\d{2,4}\\)?\\s?\\d{2,5}(\\s?\\d{2,5})?)' + // UK phone numbers without +44 (partial and complete)
    '|(\\b\\d{1,3}[-\\s]?){9,}\\d\\b' + // General phone numbers
    '|(\\b\\d(-\\d){9,}\\d\\b)' + // Numbers with dashes
    '|\\(\\d{10,11}\\)' + // UK phone numbers in parentheses
    '|\\b0\\d{4}\\s\\d{3}\\s\\d{3}\\b', // Additional pattern for UK phone numbers
    'gi'
);